<template>
  <main>
    <div class="bg-color" v-if="showBg"></div>
    <div class="bg-picture" v-if="showBgImg"></div>
    <div class="share-details-container">
      <section class="pc-terminal-head" v-if="!isMoble">
        <!-- <header class="content-header">
          <a v-if="routerData == 's'" href="http://mindyushu.com" target="_black">
            <div class="content-header-log">
              <img src="../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/logo.png" alt="" />
              <div class="content-header-text">
                {{ getString(strings.About_App_Name) }}
              </div>
            </div>
          </a>
        </header> -->
        <div class="group-detail-header">
          <img class="group-detail-header-img" :src="httpImageToPrefix(checkGroupDataDetail.cover)" alt="" />
          <div class="group-detail-header-right">
            <div class="group-detail-title">{{ checkGroupDataDetail.name }}</div>
            <div class="group-detail-id" @click="copyGroupId()">
              {{ getString(strings.Mind_Group_Group_Indication) }}:{{checkGroupDataDetail.code}}
            </div>
            <div v-if="checkGroupDataDetail.desc != '' && checkGroupDataDetail.desc != null"
              class="group-detail-introductory">{{ checkGroupDataDetail.desc }}</div>
            <div class="group-detail-introductory" v-else>
              {{ getString(strings.Mind_Group_The_Creator_Is_Lazy_And_Hasnt_Been_Introduced_Yet) }}
            </div>
            <div class="group-detail-button-box">
              <div v-for="(item,index) in checkGroupDataDetail.tags" :key="index" class="group-botton-class">{{ item }}
              </div>
            </div>
          </div>
          <!-- 判断加入共享组是否需要付费加入或验证消息，如果需要则下方按钮为“申请加入”，不需要则为“立即加入；如果已加入，则显示已加入” -->
          <button class="join-now" v-if="((!checkGroupDataDetail.isJoined) && (!checkGroupDataDetail.needVerification) && (checkGroupDataDetail.mbeansRequired == 0))"
            @click="immediatelyToJoin">
            {{ getString(strings.Mind_Group_Immediately_To_Join) }}
          </button>
          <button class="join-now" v-else-if="!checkGroupDataDetail.isJoined && checkGroupDataDetail.mbeansRequired && checkGroupDataDetail.mbeansRequired != 0"
            @click="applyToJoin(1)">
            <label class="cursor-pointer">{{ getString(strings.Mind_Group_Apply_To_Join) }}</label>
            <label class="cursor-pointer" style="margin-left: 6px;">{{ checkGroupDataDetail.mbeansRequired }}</label>
            <img src="../../assets/img/user/member/mBean.svg" alt="" width="14px" style="margin:-2px 0 0 3px;">
          </button>
          <button class="join-now" v-else-if="!checkGroupDataDetail.isJoined && checkGroupDataDetail.needVerification"
            @click="applyToJoin">
            {{ getString(strings.Mind_Group_Apply_To_Join) }}
          </button>
          <button type="button" disabled class="join-now"
            style="cursor: default;background-color: #E8E8E8;color: #666666;border:0;" v-else>
            {{ getString(strings.Mind_Group_Joined) }}
          </button>
        </div>
      </section>
      <section class="mobile-terminal-head" v-else>
        <header class="mobile-content-header">
          <!-- 分享logo路由 -->
          <a v-if="routerData == 's'" href="http://mindyushu.com" target="_black">
            <div class="content-header-log">
              <img src="../../assets/img/code/logo02.svg" alt="" />
              <!-- <div class="content-header-text">
                {{ getString(strings.About_App_Name) }}
              </div> -->
            </div>
          </a>
          <div class="download-mind" @click="toDownload">
            {{ getString(strings.Mind_Group_Download_App) }}
          </div>
        </header>
        <div class="group-mobile-header">
          <div class="group-mobile-header-right">
            <div class="group-mobile-title">{{ checkGroupDataDetail.name }}</div>
          <!-- 判断加入共享组是否需要付费加入或验证消息，如果需要则下方按钮为“申请加入”，不需要则为“立即加入；如果已加入，则显示已加入” -->
            <button class="mobile-join-now"
              v-if="((!checkGroupDataDetail.isJoined) && (!checkGroupDataDetail.needVerification) && (checkGroupDataDetail.mbeansRequired == 0))"
              @click="immediatelyToJoin">
              {{ getString(strings.Mind_Group_Immediately_To_Join) }}
            </button>
            <button class="mobile-join-now" v-else-if="!checkGroupDataDetail.isJoined && checkGroupDataDetail.mbeansRequired && checkGroupDataDetail.mbeansRequired != 0"
              @click="applyToJoin(1)">
              <label class="cursor-pointer">{{ getString(strings.Mind_Group_Immediately_To_Join) }}</label>
              <label class="cursor-pointer" style="margin-left: 3px;">{{ checkGroupDataDetail.mbeansRequired }}</label>
              <img src="../../assets/img/storm/mbeans.svg" alt="" width="10px" style="margin:-3px 0 0 1px;">
            </button>
            <button class="mobile-join-now"
              v-else-if="!checkGroupDataDetail.isJoined && checkGroupDataDetail.needVerification" @click="applyToJoin">
              {{ getString(strings.Mind_Group_Apply_To_Join) }}
            </button>
            <button type="button" disabled class="mobile-join-now"
              style="cursor: default;background-color: #E8E8E8;color: #666666;" v-else>
              {{ getString(strings.Mind_Group_Joined) }}
            </button>
            <div class="group-mobile-id" @click="copyGroupId()">
              {{ getString(strings.Mind_Group_Group_Indication) }}:{{checkGroupDataDetail.code}}
            </div>
            <div v-if="checkGroupDataDetail.desc != '' && checkGroupDataDetail.desc != null"
              class="group-mobile-introductory">{{ checkGroupDataDetail.desc }}</div>
            <div class="group-mobile-introductory" v-else>
              {{ getString(strings.Mind_Group_The_Creator_Is_Lazy_And_Hasnt_Been_Introduced_Yet) }}
            </div>
            <div class="group-mobile-button-box">
              <div v-for="(item,index) in checkGroupDataDetail.tags" :key="index" class="group-botton-mobile">{{ item }}
              </div>
            </div>
          </div>

          <img class="group-mobile-header-img" :src="httpImageToPrefix(checkGroupDataDetail.cover)" alt="" />
        </div>
      </section>
      <section class="public-sector">
        <div class="bg-placeholder"></div>
        <!-- 共享组公告 -->
        <div class="group-notice">
          <div class="gruop-secondary-title">{{ getString(strings.Mind_Group_Shared_Group_Announcement) }}</div>
          <template v-if="checkGroupDataDetail.notice != '' && checkGroupDataDetail.notice != null">
            <div class="group-notice-content-father">
              <div ref="groupNoticeContent" class="group-notice-content" :class="showNotice ? '': 'beyondHidden'">
                {{ checkGroupDataDetail.notice }}
              </div>
              <div v-if="allowShowButton && showNotice == false" @click="showNoticeChange" class="click-unfold">
                <div class="left-linear-gradient">

                </div>
                <div class="click-unfold-text">
                  {{ getString(strings.Mind_Group_Click_On) }}
                </div>
              </div>
              <div v-if="showNotice" @click="showNoticeChange" class="click-unfold">
                <div class="left-linear-gradient">

                </div>
                <div class="click-unfold-text">
                  {{ getString(strings.Mind_Group_Click_On_The_Hidden) }}
                </div>

              </div>
            </div>
          </template>   
          <template v-else>
            <div class="group-notice-content" style="text-align:center;" :style="{'padding': '20px 0'}">
              <div class="no-announcement" style="display: flex;justify-content: center;align-items: center;">
                <img src="../../../static/img/share/no_announcement.svg" alt="" style="margin-bottom: 8px;">
              </div>
              {{ getString(strings.Mind_Group_No_Announcement) }}
            </div>
          </template>
        </div>
        <div class="bg-placeholder"></div>
        <!-- 共享组成员 -->
        <div class="group-member">
          <div class="gruop-secondary-title">{{getString(strings.Mind_Group_Shared_Group_Membership) }}
          </div>
          <div class="group-member-content" :class="showAllMembers? 'beyondCoverShow' : '' ">
            <div v-for="(item,index) in checkGroupDataDetail.members" :key="index" class="group-member-detail">
              <div class="group-user-header">
                <img v-if="item.isMember" class="group-user-img" src="../../assets/img/groupImg/crown.svg" alt="" />
                <div v-if="item.avatar" class="group-user-bg" :style="{border: item.isMember?'1px solid #FFCA69':'none',backgroundImage: 'url('+ httpImageToPrefix(item.avatar) +')'}"></div>
                <div v-else class="group-user-bg" :style="{border: item.isMember?'1px solid #FFCA69':'none',backgroundImage: 'url(https://r.mindyushu.com/r/config/image/default_avatar.png)'}"></div>
              </div>
              
              <!-- <img :src="httpImageToPrefix(item.avatar)" alt="" /> -->
              <div class="group-name" :title="item.username">{{ item.username }}</div>
            </div>
          </div>

          <div class="group-number">
            {{getString(strings.Mind_Group_Altogether) }}{{ checkGroupDataDetail.memberCount }}<!-- {{getString(strings.Mind_Group_Number_Of_People) }} -->
            <a-icon style="cursor:auto" v-if="this.showAllMembers == false" type="right" @click="showAllMembersChange()" />
            <a-icon style="cursor:auto" v-else type="down" @click="showAllMembersChange()" />
          </div>
        </div>
        <div class="bg-placeholder"></div>
        <!-- 思维导图 -->
        <div class="group-mind">
          <div class="gruop-secondary-title">{{getString(strings.Mind_Group_Mind_Mapping) }}</div>
          <div v-if="checkGroupDataDetail.gmapCount > 0" class="group-mind-content">
            <div v-for="(item,index) in checkGroupDataDetail.gmaps" :key="index" class="group-mind-detail">
              <img class="mind-img"  v-real-img="httpImageToPrefix(item.cover)" src="../../assets/img/user/plate_plan_4.jpg"  alt="">
              <div class="group-mind-name">
                {{ item.title }}
              </div>
            </div>
          </div>
          <section class="group-mind-empty" v-else>
            <img class="group-mind-empty-img" src="../../../static/img/share/mind_map.svg" alt="">
            <div class="group-mind-empty-content">
              <span>{{ getString(strings.Mind_Group_No_Content) }}</span> <!-- 暂无内容 -->
            </div>
          </section>
          <div class="group-number" v-if="checkGroupDataDetail.gmapCount > 0">
            {{getString(strings.Mind_Group_Altogether) }}{{ checkGroupDataDetail.gmapCount }}<!-- {{ getString(strings.Mind_Group_Individual) }} -->
            <a-icon type="right" @click="showAllMindChange()" />
            <!-- <a-icon v-else type="down" @click="showAllMindChange()" /> -->
          </div>
        </div>
        <div class="bg-placeholder"></div>
        <!-- 留言区 -->
        <div class="group-comment">
          <div class="gruop-secondary-title">{{ getString(strings.Mind_Group_Message_Area) }}</div>
          <div v-if="checkGroupDataDetail.gcommentCount > 0" class="group-comment-content">
            <div class="storm-user-comment" v-if="checkGroupDataDetail.gcommentCount > 0">
              <div class="storm-user-comment-message" v-for="(item, index) in checkGroupDataDetail.gcomments"
                :key="index">
                <div class="storm-user-comment-box">
                  <div class="storm-user-header">
                    <div v-if="item.isMember" class="storm-user-img-ismember">
                      <img :src="httpImageToPrefix(item.userAvatar)" alt="" />
                    </div>
                    <div v-else class="storm-user-img">
                      <img :src="httpImageToPrefix(item.userAvatar)"
                        onerror="this.src='/static/img/userData/avatar_yellow_man.png'" alt="" />
                    </div>
                  </div>
                  <div class="storm-comment-content">
                    <div class="comment-content-header">
                      <div class="comment-user-name">
                        <span>{{ item.username }}</span>
                      </div>
                      <div class="comment-cotnent-detials" v-if="false">
                        <div class="chips-details-single" @click="toDownload">
                          <img src="../../../static/img/share/fabulous.svg" alt="" />
                          <span></span>
                        </div>
                        <div class="chips-details-single" @click="toDownload">
                          <img src="../../../static/img/userData/news_logo.png" alt="" />
                          <span></span>
                        </div>
                      </div>
                    </div>

                    <div class="comment-content-reply">
                      <div class="comment-time">
                        {{ formatTime(item.createTime) }}
                      </div>
                    </div>
                    <div class="comment-content-box">
                      <div class="comment-content-text">
                        <span>{{ item.content }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="storm-comment-reply" v-if="checkGroupDataDetail.gcomments[index].topComments">
                  <div class="storm-user-comment-message-reply"
                    v-for="(itemReply, replyIndex) in checkGroupDataDetail.gcomments[index].topComments"
                    :key="replyIndex">
                    <div class="storm-user-header">
                      <div v-if="itemReply.isMember" class="storm-user-img-ismember">
                        <img :src="httpImageToPrefix(itemReply.userAvatar)" alt="" />
                      </div>
                      <div v-else class="storm-user-img">
                        <img :src="httpImageToPrefix(itemReply.userAvatar)"
                          onerror="this.src='/static/img/userData/avatar_yellow_man.png'" alt="" />
                      </div>
                    </div>
                    <div class="storm-comment-content">
                      <div class="comment-content-header">
                        <div class="comment-user-name">
                          <span>{{ itemReply.username }}</span>
                        </div>
                        <div class="comment-cotnent-detials">
                          <div class="chips-details-single" @click="toDownload">
                            <img src="../../../static/img/share/fabulous.svg" alt="" />
                            <span></span>
                          </div>
                          <div class="chips-details-single" @click="toDownload">
                            <img src="../../../static/img/userData/news_logo.png" alt="" />
                            <span></span>
                          </div>
                        </div>
                      </div>

                      <div class="comment-content-reply">
                        <div class="comment-time">
                          {{ formatTime(itemReply.createTime) }}
                        </div>
                      </div>
                      <div class="comment-content-box">
                        <div class="comment-content-text">
                          <span>{{ itemReply.content }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section class="group-comment-empty" v-else>

            <span>{{ getString(strings.Mind_Group_No_Message) }}</span>
            <label @click="toDownload">{{ getString(strings.Mind_Group_Leave_Message) }}</label>
            <span>{{ getString(strings.Mind_Group_Bar) }}</span>
          </section>
          <div class="group-number" v-if="checkGroupDataDetail.gcommentCount > 0" style="cursor: pointer;" @click="showAllMindChange()">
           {{ getString(strings.Mind_Group_See_More) }}
            <a-icon type="right" />
          </div>
        </div>
      </section>
    </div>
    <!-- 商品购物车 -->
    <div class="Shopping-Father-Box" @click.stop="HideShoppingModel(false)">
      <transition name="slide-fade">
        <ShoppingModel
          v-if="shoppingModel"
          :showMsg="true"
          @HideShoppingModel="HideShoppingModel"
        />
      </transition>
    </div>
    <!-- 登录弹窗 -->
    <LoginComponents v-if="isShowLoginModel"></LoginComponents>
    <!-- 移动端登录不再使用 -->
    <!-- <section class="moble-login" v-if="false">
      <div class="row">
        <div class="lright-cont">
          <div class="Login-header">
            <div class="Login-container">
              <div class="lc-titel">
                <h2>{{ getString(strings.User_Lolgin_Welcome_To_Login) }}</h2>

              </div>
            </div>
            <div @click.stop="closeLogin()" class="moble-close-modal">
              <a-icon type="close" />
            </div>
          </div>
          <div class="login-content">
            <div class="login">
              <a-form :form="form" @submit="handleSubmitLogin">
                <div class="drop-down d-quhao" style="margin-top: 11px;">
                  <img src="../../assets/img/code/phone_mobel_logo.png" alt="" />

                  <div class="country-code">+86</div>
                </div>
                <div class="modal-backdrop fade in" style="z-index: 1"></div>
                <a-form-item>
                  <a-input class="login-phone-number"
                    oninput="if(value.length>11)value=value.slice(0,11);value=value.replace(/[^\d]/g,'');" :placeholder="
                            getString(strings.User_Lolgin_Please_Enter_Phone_Number)
                          " @blur="phoneNumberBlurForValidate" @input="inputForValidatePhoneNumber" ref="phoneNumber" allow-clear v-decorator="[
                            'phoneNumber',
                            {
                              rules: [{ validator: phoneNumber }],
                            },
                          ]">
                  </a-input>
                </a-form-item>
                <div class="drop-down">
                  <img src="../../assets/img/code/verification_code_logo.png" alt="" />
                </div>
                <a-form-item>
                  <div class="dr-agroup">
                    <a-input allow-clear
                      oninput="if(value.length>6)value=value.slice(0,6);value=value.replace(/[^\d]/g,'')" :placeholder="
                              getString(
                                strings.User_Lolgin_Please_Enter_Verification_Code
                              )
                            " ref="CodeValue" v-decorator="[
                              'captcha',
                              {
                                rules: [
                                  {
                                    required: false,
                                    message: getString(
                                      strings.Input_Please_Enter_Verification_Code
                                    ),
                                  },
                                  {
                                    validator: validataToCode,
                                  },
                                ],
                              },
                            ]">
                    </a-input>
                    <div @click="handleSubmitCode">
                      <a-button class="dra-btn scode" disabled v-if="showTimeData">
                        {{ timeData }}
                        <span>s</span>
                      </a-button>
                      <a-button class="dra-btn" :class="canGetVerificationCode?'can-use':'out-of-commission'" v-else>
                        {{ getString(strings.Get_Verification_Code) }}
                      </a-button>
                    </div>
                  </div>
                </a-form-item>
                <section class="enter-invitation-code" v-if="enterInvitationCode">
                  <span @click="enterInvitationCode=!enterInvitationCode"> {{ getString(strings.Mind_Group_Enter_Invitation_Code) }}</span>
                </section>
                <section v-else>
                  <div class="drop-down">
                    <img src="../../assets/img/code/Invitation_code_logo.png" alt="" />
                  </div>
                  <div class="drop-down-before">{{getString(strings.User_Lolgin_Optional)}}</div>
                  <a-form-item style="margin-bottom: 0;">
                    <div class="dr-agroup">
                      <a-input allow-clear :placeholder="
                                getString(
                                  strings.User_Lolgin_Please_Enter_Invitation_Code
                                )
                              " ref="invitation" v-decorator="[
                                'invitation',
                                {
                                  rules: [
                                    {
                                      required: false,
                                      message: getString(
                                        strings.User_Lolgin_Please_Correct_Enter_Invitation_Code
                                      ),
                                    },
                                    {
                                      validator: invitationToCode,
                                    },
                                  ],
                                },
                                maxLength=12
                              ]">
                      </a-input>
                    </div>
                  </a-form-item>
                </section>
                <a-form-item v-bind="tailFormItemLayout" style="margin-bottom: 0;">
                  <a-button class="Login-Button" type="primary" html-type="submit">
                    {{ getString(strings.User_Lolgin_Login) }}
                  </a-button>
                </a-form-item>
              </a-form>
            </div>
          </div>

        </div>
      </div>
    </section> -->
    <div class="group-tag-strom-page" @click.stop="cklickModal(false)" v-show="tagModalShow">
      <div @click.stop="cklickModal(true)" class="group-tag-strom-page-center">
        <div @click.stop="cklickModal(false)" class="close-modal">
          <a-icon type="close" />
        </div>
        <div class="group-tag-strom-detail-list">
          <div class="group-tag-strom-detail-name">
            {{getString(strings.Mind_Group_Apply_To_Join_Sharing_Group) }}
          </div>
          <div class="group-tag-strom-title">
            {{ checkGroupDataDetail.name }}
          </div>

        </div>
        <div>
          <textarea class="group-tag-strom-detail-input" v-model="groupTagName" @input="inputArea()"
            v-on:keyup.enter="affirm()" :placeholder="getString(strings.Reasons_For_Application_0_30_Words)">
                  </textarea>
          <div class="tag-warning">
            {{warning}}
          </div>
        </div>
        <div class="group-tap-footer">
          <!-- <div @click.stop="cancel()" class="group-tap-footer-button">
                      {{getString(strings.Mind_Group_Cancel) }}
                  </div> -->
          <div @click.stop="affirm()" class="group-tap-footer-button">
            {{getString(strings.Mind_Group_Send_Out) }}
            <!-- 发送 -->
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import {
    postGroupProfile,
    postGroupJoin
  } from '../../common/netWork/group_api';
  import getString from "../../common/lang/language";
  import strings from "../../common/lang/strings";
  import httpImageToPrefix from '../../utils/httpImageToPrefix';
  import {
    mapMutations
  } from "vuex";
  import LoginComponents from "../../components/loginComponents/LoginComponents";
  import {
    postUserLogin,
    postUserCaptcha,
    postUserMe,
  } from "../../common/netWork/base_api";
  import {
    doLogin,
    setJwt
  } from "../../common/netWork/base";
  import dataTimeToFormats from "../../utils/timestampToTime";
  import regExp from "../../components/loginComponents/regular.js";
  import ShoppingModel from "../../components/shopping/ShoppingModel";

if (
  typeof navigator === "object" &&
  typeof navigator.userAgent === "string" &&
  navigator.userAgent.indexOf("Electron") >= 0
) {
  var { ipcRenderer, remote } = window.require("electron");
}


  const { phoneNumberRegExp } = regExp;


  let id = null;

  export default {
    components: {
      LoginComponents,
      ShoppingModel
    },
    data() {
      return {
        strings,
        isShowLoginModel: false, //登陆
        showBg: false,
        showBgImg: true,
        isMoble: false,
        isMobleShowLoginModel: false,
        routerData: 's',
        checkGroupDataDetail: {}, //当前共享组的详情数据
        showNotice: false,
        allowShowButton: false, //当前的公告内容是否多到可以展示展开按钮。
        showAllMembers: false,
        tagModalShow: false, //申请加入共享组弹框
        groupTagName: '', //申请理由
        warning: '',
        shoppingModel: false,//购买m豆

        tailFormItemLayout: {
          wrapperCol: {},
        },
        SignInData: {
          areaCode: "",
          phoneNumber: "",
          invitation: ""
        },
        timeData: "",
        showTimeData: false,
        countryCode: 86,
        phoneNumberValidateCallback: null,
        formItemLayout: {
          labelCol: {
            xs: {
              span: 24
            },
            sm: {
              span: 8
            },
          },
          wrapperCol: {
            xs: {
              span: 24
            },
            sm: {
              span: 16
            },
          },
        },
        form: this.$form.createForm(this, {
          phoneNumber: ''
        }),
        enterInvitationCode: true, //输入邀请码
        canGetVerificationCode:false, //能够获取验证码
      }
    },
    created() {
      id = this.$router.history.current.params.id;
      this.getSharedDetailsData();
      
      let phoneCode = window.localStorage.getItem("uponenuber");
      if (
        phoneCode != "" &&
        phoneCode != null &&
        phoneCode != undefined &&
        phoneCode != "undefined"
      ) {
        this.form.setFieldsValue({
          phoneNumber: phoneCode,
        });

        window.localStorage.setItem("uponenuber", "");
      }
      // console.log(this.form);
    },
    mounted() {
      setTimeout(() => {
        this.setNoticeBox(this.$refs.groupNoticeContent);
      }, 100)
      let client_width = document.documentElement.clientWidth;
      if (client_width < 682) {
        this.isMoble = true;
        this.showBgImg = false;
      }
      // this.electronMessageData();
    },
    methods: {
      ...mapMutations([
        "showLoginModel",
        "setUserIfLogin",
        "isShowShoppingModel",

      ]),
      // electronMessageData() {
      //   if (this.$tools.isElectron()) {
      //     ipcRenderer.on("getPageData", (event, arg) => {
      //       // 接收异步消息
      //       let obj = {
      //         isEdit: true,
      //         window: arg,
      //       };
      //       ipcRenderer.send("GroupDetailsData", obj); // 发送异步消息
      //     });
      //   }
      // },

      getString(i) {
        return getString(i);
      },
      httpImageToPrefix(src) {
        return httpImageToPrefix(src)
      },
      //请求数据
      getSharedDetailsData() {
        postGroupProfile({
            id
          },
          // {id:'607698d420c51290567de549'},
          (res) => {
            this.checkGroupDataDetail = res;
            /*this.checkGroupDataDetail.gcommentCount = 0
             this.checkGroupDataDetail.gcomments = [{
              "id": "600fc3d1fd2a98ef6f9a73a2",
              "toCommentId": "",
              "userId": "6003b86a705c852d4c2bdcc1",
              "username": "AkAnG",
              "userAvatar": "r/user/avatar/707eae8a17f817d512c2cc18f3ad02a1.jpeg",
              "isMember": false,
              "atId": "",
              "atUsername": "",
              "voteCount": 0,
              "voted": false,
              "content": "越来越方便好用，推荐",
              "topComments": [{
                "id": "607d3393434b1143a1ea217c",
                "toCommentId": "600fc3d1fd2a98ef6f9a73a2",
                "userId": "606bfae15ef8c28092be800a",
                "username": "吴迪",
                "userAvatar": "r/config/image/default_avatar.png",
                "isMember": false,
                "atId": "",
                "atUsername": "",
                "voteCount": 0,
                "voted": false,
                "content": "天生我材必有用，千金散尽还复来。",
                "topComments": [],
                "commentCount": 0,
                "createTime": 1618817939
              }],
              "commentCount": 1,
              "createTime": 1611200100
            }, ] */
          },
          (error) => {
            this.$message.error(error.desc);
          });
      },
      copyGroupId() {
        // this.copyToClip(this.checkGroupDataDetail.code)
      },
      showNoticeChange() {
        this.showNotice = !this.showNotice;
      },
      showAllMembersChange() {
       this.toDownload();

      },
      showAllMindChange() {
        this.toDownload();

      },
      setNoticeBox(dom) {
        this.allowShowButton = false
        if (dom == null) {
          return
        }
        let height = dom.clientHeight;
        // console.log(dom, height);
        if (height > 60) {
          this.allowShowButton = true;
        }
      },
      //请求加入群组接口
      joinTheGroup(type) {
        const meMessage = JSON.parse(localStorage.getItem("meMessage"));
        if (!!meMessage) {
          // console.log({
          //   groupId: id,
          //   message: this.groupTagName
          // })
          postGroupJoin({
              groupId: id,
              message: this.groupTagName
            },
            (res) => {
              if (type==1) {
                this.$message.success(getString(strings.Mind_Group_Join_Successfully));
              }
              this.getSharedDetailsData();
            },
            (error) => {
              if (type==1 && !this.isMoble) {
                if (error.code==152) {//M豆数量不足
                  let that = this;
                  this.$confirm({
                    title: getString(strings.Mind_Tips),
                    content: getString(strings.Mind_Your_M_Bean_Insufficient_Whether_Go__Purchase_M_Bean),
                    centered: true,
                    okText:getString(strings.Global_Ok),
                    cancelText:getString(strings.Global_Cancel),
                    onOk() {
                      that.ShowShoppingModel()
                    },
                    onCancel() {},
                  });
                  return
                }
              }
              this.$message.error(error.desc);
            });;
        } else {
          // if (this.isMoble) {//移动端登录不再使用
          //   this.isMobleShowLoginModel = this.showBg = (true);
          // } else {
          //   this.showLoginModel(true);
          // }
          this.showLoginModel(true)
          return;
        }
      },

      immediatelyToJoin() {
        this.joinTheGroup();
      },
      applyToJoin(type) {
        const meMessage = JSON.parse(localStorage.getItem("meMessage"));
        if (!!meMessage) {
          if (type==1) {
            let that = this;
            this.$confirm({
              title: getString(strings.Mind_Tips),
              content: getString(strings.Mind_Shooping_Confirm_Payment)+this.checkGroupDataDetail.mbeansRequired+getString(strings.Mind_MBean),
              centered: true,
              okText:getString(strings.Global_Ok),
              cancelText:getString(strings.Global_Cancel),
              onOk() {
                that.groupTagName = '';
                that.joinTheGroup(1);
              },
              onCancel() {},
            });
          }else{
            this.cklickModal(true);
          }
        } else {
          // if (this.isMoble) {//移动端登录不再使用
          //   this.isMobleShowLoginModel = this.showBg = (true);
          // } else {
          //   this.showLoginModel(true);
          // }
          this.showLoginModel(true)
          return;
        }
      },
      cklickModal(val) {
        this.tagModalShow = val;
        if (val == false) { //当隐藏模态框时
          this.groupTagName = ''
          this.warning = ''
        }
      },
      affirm() {
        if (this.trim(this.groupTagName) == '') {
          this.warning = getString(strings.Mind_Group_The_Content_Cannot_Be_Empty)
          return
        }
        this.joinTheGroup();
        this.$message.success(getString(strings.Mind_Group_Application_Sent_Successfully));
        this.cklickModal(false);
      },
      cancel() {
        this.cklickModal(false);
      },
      trim(str) { //判断有首尾空格去除空格
        return str.replace(/^\s+|\s+$/g, '');
      },
      inputArea() { //不允许输入或黏贴超过20个字符
        this.warning = ''
        if (this.groupTagName.length > 30) {
          this.groupTagName = this.groupTagName.slice(0, 30);
        }
      },
       //购物车模块
      ShowShoppingModel() {
        this.shoppingModel = !this.shoppingModel;
        this.showBg = !this.showBg;
        this.isShowShoppingModel(true);
      },
      HideShoppingModel(Account) {
        this.shoppingModel = Account;
        this.showBg = false;
        this.isShowShoppingModel(false);
      },

      toDownload() {
        if (this.isMoble) {
          this.$router.push("/download");
        }else{
          return
          this.$router.push("/Home/GroupManagement");
        }
      },
      //时间处理
      formatTime(time) {
        return dataTimeToFormats.formatTime(time);
      },
      /*closeLogin() {
        this.isMobleShowLoginModel = this.showBg = (false);
      },
      // 登录
      handleSubmitLogin(e) {
        e.preventDefault();
        this.SignInData.areaCode = "+" + this.countryCode;
        this.SignInData.phoneNumber = this.$refs.phoneNumber.value;

        //输入邀请码
        if (!this.enterInvitationCode) {
          this.SignInData.invitation = this.$refs.invitation.value;
        }
        const SignInData = this.SignInData;
        var _this = this;
        this.form.validateFieldsAndScroll(
          ["phoneNumber", "captcha"], {
            first: true
          },
          (err, values) => {
            if (!err) {
              values.areaCode = this.SignInData.areaCode;
              if (!this.enterInvitationCode) {
                values.invitationCode = this.$refs.invitation.value;
              }
              this.loginRequest(values)
                .then((data) => {
                  setJwt(data.jwt);
                  this.$message.success(
                    getString(strings.Message_Tips_Login_Success)
                  );
                  return this.userMessage();
                })
                .then((data) => {
                  this.closeLogin();
                  this.setUserIfLogin(true);
                  this.getSharedDetailsData();
                });
            }
          }
        );
      },
      loginRequest(obj) {
        let that = this;
        return new Promise(function(resolve, reject) {
          postUserLogin(
            obj,
            (res) => {
              doLogin(res);
              resolve(res);
            },
            (error) => {
              reject(error);
              that.$message.error(error.desc);
            }
          );
        });
      },
      userMessage(obj = {}) {
        let that = this;
        return new Promise(function(resolve, reject) {
          postUserMe(
            obj,
            (res) => {
              resolve(res);
            },
            (error) => {
              reject(error);
              that.$message.error(error.desc);
            }
          );
        });
      },
      // 获取验证码
      handleSubmitCode(e) {

        this.SignInData.areaCode = "+" + this.countryCode;
        this.SignInData.phoneNumber = this.$refs.phoneNumber.value;
        const SignInData = this.SignInData;
        // console.log(SignInData);
        this.form.validateFieldsAndScroll(
          ["phoneNumber"], {
            first: true,
          },
          (err, values) => {
            if (!err) {
              postUserCaptcha(
                SignInData,
                (res) => {
                  if (res == false) {
                    this.showTimeData = res;
                  } else {
                    this.showTimeData = true;
                    this.timeData = 60;
                    let countDown = setInterval(() => {
                      if (this.timeData < 1) {
                        this.timeData = 0;
                        this.showTimeData = false;
                        clearInterval(countDown);
                      } else {
                        this.timeData--;
                      }
                    }, 1000);
                  }
                },
                (error) => {
                  this.$message.error(error.desc);
                }
              );
            }
          }
        );

      },
      // 手机号验证
      phoneNumber(rule, value, callback) {
        this.phoneNumberValidateCallback = callback;
        if (value.substring(0, 2) == 90) {
          callback();
          return;
        }
        if ("" + this.countryCode == "86") {
          var iphoneNumber = phoneNumberRegExp;
          if (!(!iphoneNumber.test(value) && value)) {
            callback();
          }
        } else {
          if ((value + "").length > 4 && (value + "").length < 20) {
            callback();
          }
        }
      },
      setGetVerificationCode(val){
        this.canGetVerificationCode = val;
      },
      inputForValidatePhoneNumber(){
        let phoneNumberNode = document.getElementById('phoneNumber');
        if(phoneNumberNode){
          let value = phoneNumberNode.value;
          if(value){
            if ("" + this.countryCode == "86") {
              let iphoneNumber = phoneNumberRegExp;
              if (iphoneNumber.test(value)) {
                this.setGetVerificationCode(true);
              } else {
                this.setGetVerificationCode(false);
              }
            }
          } else {
            this.setGetVerificationCode(false);
          }
        }
      },
      //手机号验证焦点离开判断
      phoneNumberBlurForValidate(e) {
        let phoneNumberNode = this.$refs.phoneNumber;
        if(!phoneNumberNode){
          return;
        }
        let value = phoneNumberNode.value;
        if (this.phoneNumberValidateCallback == null) {
          return;
        }
        if(!value){
          this.phoneNumberValidateCallback(getString(strings.Input_Please_Enter_A_Valid_Phone_Number) + "!");
          this.setGetVerificationCode(false);
          return;
        }
        if (value.substring(0, 2) == 90) {
          this.phoneNumberValidateCallback();
          this.setGetVerificationCode(true);
          return;
        }
        if ("" + this.countryCode == "86") {
          var iphoneNumber = phoneNumberRegExp;
          if (!iphoneNumber.test(value) && value) {
            this.phoneNumberValidateCallback(getString(strings.Input_Please_Enter_A_Valid_Phone_Number) + "!");
            this.setGetVerificationCode(false);
          } else {
            this.phoneNumberValidateCallback();
            this.setGetVerificationCode(true);
          }
        } else {
          if ((value + "").length <= 4 || (value + "").length >= 20) {
            this.phoneNumberValidateCallback(getString(strings.Input_Please_Enter_A_Valid_Phone_Number) + "!");
            this.setGetVerificationCode(false);
          } else {
            this.phoneNumberValidateCallback();
            this.setGetVerificationCode(true);
          }
        }

      },
      // 验证码验证
      validataToCode(rule, value, callback) {
        const iCaptcha = /^[0-9]*$/;
        if (!iCaptcha.test(value) && value) {
          callback("验证码错误");
        } else {
          callback();
        }
      },
      //邀请码验证
      invitationToCode(rule, value, callback) {
        const iCaptcha = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/;
        if (!iCaptcha.test(value) && value) {
          callback("邀请码输入错误");
        } else {
          callback();
        }
      },*/

      
    },
    watch: {
      "$store.state.showLogin"(newLoginShowLogin, olLoginShowLogin) {
        this.isShowLoginModel = newLoginShowLogin;
        this.showBg = newLoginShowLogin;
        this.$store.state.useShortcut = !newLoginShowLogin;//是否可以使用快捷键

      },
    }

  }
</script>

<style lang="less" scoped>
  /**背景 */
  .bg-color {
    background-color: black;
    opacity: 0.5;
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
    transition: 0.5s;
  }

  .bg-picture {
    background: url(../../../static/img/share/SharedDetailsBgPicture.png);
    background-size: 100% 100%;
    opacity: 0.5;
    position: fixed;
    width: 100%;
    height: 100%;
    transition: 0.5s;
  }
  .bg-placeholder{
    width: 1200px;
    height: 10px;
    background: #EFEFEF;
    position: relative;
    left: -80px;
  }
  .Shopping-Father-Box {
    width: 100%;
    position: absolute;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
 
  .share-details-container {
    max-width: 1200px;
    // height: 1218px;
    background: #fff;
    padding: 0 80px;
    margin: 0 auto;
    position: relative;
    

    .pc-terminal-head {
      width: 100%;

      // height: 100%;
      .content-header {
        width: 100%;
        height: 56px;
        display: flex;
        align-items: center;

        a {
          width: 169px;

          .content-header-log {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .content-header-text {
              width: auto;
              height: 22px;
              font-size: 14px;
              font-family: Noto Sans SC;
              font-weight: 400;
              line-height: 22px;
              color: #fd492b;
              opacity: 1;
            }
          }
        }
      }

      .group-detail-header {
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        padding-top: 30px;
        padding-bottom: 25px;
        border-bottom: 1px solid #fbfbfb;

        .group-detail-header-img {
          width: 88px;
          height: 88px;
          border-radius: 4px;
        }

        .group-detail-header-right {
          margin-left: 10px;

          .group-detail-title {
            font-size: 16px;
            color: #333;
            height: 22px;
            line-height: 22px;
            font-weight: bold;
          }

          .group-detail-id {
            font-size: 12px;
            color: #FF4220;
            // cursor:pointer;
          }

          .group-detail-introductory {
            font-size: 12px;
            color: #666;
          }

          .group-detail-button-box {
            margin-top: 1px;

            .group-botton-class {
              margin-right: 20px;
              display: inline-block;
              color: #999;
              font-size: 14px;
              padding: 3px 16px;
              border-radius: 25px;
              background-color: #EEEEEE;
            }
          }
        }
      }

      .join-now {
        padding: 0 8px;
        min-width: 108px;
        height: 36px;
        border: 0;
        outline: none;
        background: #FFFFFF;
        border: 1px solid #FD492B;
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px;
        color: #333;
        position: absolute;
        right: 0;
      }
    }

    .mobile-terminal-head {
      width: 100%;

      // height: 100%;
      .mobile-content-header {
        width: 100%;
        height: 52px;
        padding: 0 20px;
        background: #F8725A;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        a {
          width: 169px;

          .content-header-log {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .content-header-text {
              width: auto;
              height: 22px;
              font-size: 14px;
              font-family: Noto Sans SC;
              font-weight: 400;
              line-height: 22px;
              color: #FFFFFF;
              opacity: 1;
            }
          }
        }

        .download-mind {
          width: 78px;
          height: 28px;
          line-height: 28px;
          text-align: center;
          border: 1px solid #FFFFFF;
          opacity: 1;
          border-radius: 60px;
          color: #FFFFFF;
          cursor: pointer;
        }
      }

      .group-mobile-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding-top: 70px;
        padding-bottom: 25px;
        border-bottom: 1px solid #fbfbfb;

        .group-mobile-header-img {
          width: 88px;
          height: 88px;
          border-radius: 4px;
        }

        .group-mobile-header-right {
          // margin-left: 16px;
          min-width: 60%;
          position: relative;

          .group-mobile-title {
            font-size: 16px;
            color: #333;
            height: 22px;
            line-height: 22px;
          }

          .mobile-join-now {
            // width: 78px;
            padding: 0 10px;
            height: 28px;
            border: 0;
            outline: none;
            background: #F8725A;
            border-radius: 60px;
            cursor: pointer;
            font-size: 12px;
            color: #fff;
            position: absolute;
            top: 0;
            right: 20px;
          }

          .group-mobile-id {
            font-size: 12px;
            color: #FF4220;
            margin: 4px 0;
          }

          .group-mobile-introductory {
            font-size: 12px;
            color: #999;
          }

          .group-mobile-button-box {
            margin-top: 1px;

            .group-botton-mobile {
              margin-right: 20px;
              display: inline-block;
              color: #EC705A;
              font-size: 12px;
              padding: 3px 10px;
              border-radius: 25px;
              background: #F6F6F6;
            }
          }
        }
      }
    }

    .public-sector {
      width: 100%;

      .gruop-secondary-title {
        padding-bottom: 10px;
        font-size: 14px;
        font-weight: bold;
        color: #333;
      }

      .group-number {
        position: absolute;
        right: 0px;
        top: 20px;
      }

      .group-notice {
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #fbfbfb;

        .group-notice-content-father {
          position: relative;

          .group-notice-content {
            font-size: 12px;
            color: #999;
            word-wrap: break-word;
          }

          .beyondHidden {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            max-height: 42px;
          }

          .click-unfold {
            position: absolute;
            right: 0;
            bottom: 0;
            color: #FB6F57;
            font-size: 14px;
            display: inline-block;
            cursor: pointer;
            // padding-left: 40px;
            display: flex;

            .left-linear-gradient {
              width: 20px;
              background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #F0F2F8);
            }

            .click-unfold-text {
              background-color: #F0F2F8;
            }
          }
        }
      }

      .group-member {
        padding: 20px 0;
        border-bottom: 1px solid #fbfbfb;
        position: relative;

        .group-member-content {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          min-height: 61px;
          overflow: hidden;

          .group-member-detail {
            margin-left: 20px;
            margin-right: 20px;
            // margin-bottom: 20px;

            .group-user-header {
              width: 56px;
              height: 75px;
              margin: 0 auto;
              position: relative;

              .group-user-img {
                width: 40px;
                // height: 75px;
                position: absolute;
                left: 15%;
                top: 0px;
              }
              .group-user-bg {
                width: 44px;
                height: 44px;
                // border: 1px solid #FFCA69;
                position: absolute;
                left: 50%;
                bottom: 10px;
                transform: translate(-50%,0);
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center center;
                // z-index: 1;
                overflow: hidden;
                border-radius: 50%;
              }
            }

            .group-name {
              font-size: 12px;
              text-align: center;
              color: #333;
              max-width: 56px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin: auto;
            }
          }
        }

        .beyondCoverShow {
          overflow: visible;
          height: auto;
        }
      }

      .group-mind {
        padding: 20px 0;
        border-bottom: 1px solid #fbfbfb;
        position: relative;

        .group-mind-content {
          display: flex;
          // flex-wrap: wrap;
          height: 190px;
          overflow: hidden;

          .group-mind-detail {
            margin-bottom: 20px;

            .mind-img {
              width: 120px;
              height: 120px;
              border-radius: 6px;
              margin-bottom: 12px;
              // margin-left: 15px;
              margin-right: 15px;
            }

            .group-mind-name {
              font-size: 12px;
              text-align: center;
              color: #333;
              max-width: 120px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }

        // .group-mind-content-all-show {
        //     overflow: visible;
        //     height: auto;
        // }
        .group-mind-empty {
          padding: 40px 0;
          display: flex;
          justify-content: center;
          align-items: center;
          // .group-mind-empty-img{

          // }
          .group-mind-empty-content {
            width: 208px;
            height: 150px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            span {
              font-size: 14px;
              color: #C5C5C5;
            }
            
          }
        }
      }

      .group-comment {
        padding: 20px 0;
        border-bottom: 1px solid #fbfbfb;
        position: relative;

        .group-comment-empty {
          padding: 40px 0;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            font-size: 14px;
            color: #C5C5C5;
          }

          label {
            font-size: 16px;
            color: #FD492B;
          }
        }
      }
    }
  }

  .group-tag-strom-page {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, .2);
    z-index: 6000;
    .group-tag-strom-page-center {
      position: absolute;
      width: 325px;
      min-height: 325px;
      padding: 20px;
      left: 50%;
      top: 50%;
      z-index: 1000;
      transform: translate(-50%, -50%);
      background-color: #fff;
      border-radius: 4px;

      .close-modal {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 16px;
        text-align: center;
        cursor: pointer;
        font-size: 16px;
        color: #333;
        border-radius: 0 10px 0 0;
        transition: 0.3s;
      }

      .close-modal:hover {
        transition: 0.3s;
        color: #fd492b;
        transform: rotate(180deg);
      }

      .group-tag-strom-detail-list {
        width: 230px;
        font-size: 14px;
        // line-height: 40px;
        color: #333333;
        margin: 0 auto;
        margin-bottom: 20px;
        text-align: center;

        .group-tag-strom-detail-name {
          // margin-right: 20px;
          font-size: 16px;
          margin-bottom: 10px;
        }

        .group-tag-strom-title {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: #999999;
        }

      }

      .group-tag-strom-detail-input {
        width: 100%;
        // width: 230px;
        height: 142px;
        background-color: #F2F2F2;
        outline: none;
        border: 0;
        resize: none; //禁止拉伸
        border-radius: 4px;
        font-size: 14px;
        padding: 15px;

        &::placeholder {
          font-size: 14px;
          color: #999999;
          // position: absolute;
          // top: 16px;
          // left: 16px;
        }
      }

      .tag-warning {
        // text-align: center;
        color: #FD492B;
        padding: 0 5px;
      }

      .group-tap-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

        .group-tap-footer-button {
          padding: 0 12px;
          width: 100%;
          height: 44px;
          line-height: 44px;
          text-align: center;
          border-radius: 26px;
          font-size: 15px;
          background-color: #FD492B;
          color: #fff;
          cursor: pointer;
        }

      }
    }
  }

  .moble-login {
    width: 342px;
    padding: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 9999;
    background-color: #fff;
    border-radius: 5px;

    .row {
      width: 100%;

      .lright-cont {
        width: 100%;

        .moble-close-modal {
          position: absolute;
          right: 20px;
          top: 20px;
          width: 16px;
          text-align: center;
          cursor: pointer;
          font-size: 16px;
          color: #333;
          border-radius: 0 10px 0 0;
          transition: 0.3s;
        }

        .lc-titel {
          display: flex;
          align-items: center;
          justify-content: space-between;

          h2 {
            font-size: 16px;
            font-family: Microsoft YaHei;
            line-height: 22px;
            color: #333;
            opacity: 1;
            margin-bottom: 20px;
          }
        }

        .login-content {
          margin: auto;

          .login {
            height: 100%;
            position: relative;
            margin-top: 0px;

            .ant-form {
              width: 100%;

              .drop-down {
                position: absolute;
                z-index: 5;
                margin-top: 13px;
                margin-left: 16px;
                display: flex;
                align-items: center;
                .country-code {
                  width: 25px;
                  height: 20px;
                  line-height: 20px;
                  font-size: 14px;
                  color: #333;
                  margin-left: 8px;
                }
              }

              .drop-down-before {
                position: absolute;
                z-index: 5;
                margin-top: 10px;
                margin-right: 30px;
                right: 0;
                color: #b3b3b3;
              }

              .enter-invitation-code {
                width: 100%;
                height: 20px;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                color: #FD492B;
                text-align: right;
                cursor:pointer;
              }

              .ant-form-item {
                margin-bottom: 16px;

                .ant-col {
                  .ant-form-item-control {

                    .ant-form-item-children {
                      .ant-input-affix-wrapper {
                        /deep/.ant-input {
                          &:hover {
                            border-color: transparent;
                            border-right-width: 1px !important;
                            outline: 0;
                            box-shadow: 0 0 0 2px rgba(90, 90, 90, 0.2);
                          }
                        }

                        /deep/.ant-input {
                          height: 42px;
                        }

                        /deep/#phoneNumber {
                          padding-left: 30%;
                        }

                        /deep/#captcha {
                          width: 192px;
                          padding-left: 20%;
                        }

                        /deep/#invitation {
                          padding-left: 16%;
                        }
                      }

                      .dr-agroup {
                        width: 100%;
                        position: relative;
                        display: flex;
                        align-items: center;
                        .dra-btn {
                          width: 102px;
                          height: 42px;
                          font-size: 14px;
                          border-radius: 2px;
                          overflow: hidden;

                        }
                        .can-use{
                          background: #fd492b;
                          color: #fff;
                          border: none;
                        }
                        .out-of-commission{
                          border: 1px solid rgb(217 217 217);
                          color: #999999;
                        }
                      }

                      .Login-Button {
                        width: 100%;
                        height: 42px;
                        margin-top: 10px;
                        background-color: #FC492B !important;
                        border-color: #FC492B !important;
                        border-radius: 4px;
                        font-size: 16px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and(min-width:250px) and(max-width:682px) {
    .share-details-container {
      padding: 0 20px;

    }

    .group-mind-content{
      height: auto !important;
    }
    .group-mind-detail{
      margin-bottom: 8px !important;
    }
    .mind-img {
        width: 70px !important;
        height: 70px !important;
        margin-bottom: 10px !important;
        margin-right: 10px !important;
    }
    .group-mind-empty{
      padding: 30px 0 !important;
    }
    .group-comment-empty{
      padding: 30px 0 !important;
    }
    .gruop-secondary-title{
      padding-bottom:8px !important;
    }
    .storm-user-comment-message-reply{
      margin-left: 40px !important;
    }
    .storm-user-comment-box .storm-comment-content {
        width: 82% !important;
    }
    .storm-user-comment-message-reply .storm-comment-content{
        width: 80% !important;
    }
    .bg-placeholder{
      width: 100vw;
      height: 5px;
      background: #EFEFEF;
      position: relative;
      left: -20px;
    }
  }


  .storm-user-comment {
    margin: 20px 0;
    overflow: auto;
    width: 100%;

    //评论
    .storm-user-comment-message {
      .storm-user-comment-box {
        display: flex;
        min-height: 109px;
        border-bottom: 1px solid #ececec;
        align-items: center;
        flex-wrap: nowrap;

        .storm-user-header {
          width: 42px;
          height: 80px;
          margin-bottom: 12px;
          cursor: pointer;

          .storm-user-img {
            border: 1px solid #ffffff;
            width: 42px;
            height: 42px;
            border-radius: 50%;

            img {
              width: 42px;
              height: 42px;
              border-radius: 50%;
              margin-top: 12px;
            }
          }

          .storm-user-img-ismember {
            border: 1px solid #ffffff;
            border-radius: 50%;
            background-image: url(../../../static/img/userData/user_message_vip_bg.png);
            background-size: 100%;
            width: 42px;
            height: 52px;

            img {
              width: 42px;
              height: 42px;
              border-radius: 50%;
              margin-top: 12px;
            }
          }
        }

        .storm-comment-content {
          // min-width: 322px;
          width: 100%;
          margin-left: 10px;

          .comment-content-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .comment-user-name {
              font-size: 12px;
              font-family: Noto Sans SC;
              font-weight: inherit;
              line-height: 17px;
              color: #333333;
              opacity: 1;
              max-height: 20px;

              span {
                overflow: hidden;
                text-overflow: ellipsis;
                width: 60px;
              }

              .comment-author-logo {
                margin-left: 26px;
                padding: 3px 6px;
                border-radius: 2px;
                background: #ec705a;
                color: #ffffff;
              }
            }

            .comment-cotnent-detials {
              .chips-details-single {
                float: left;
                margin-left: 20px;
                display: flex;
                align-items: center;
                cursor: pointer;
              }
            }
          }

          .comment-content-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 6px;

            .comment-content-text {
              width: 266px;
              font-size: 14px;
              font-family: Noto Sans SC;
              font-weight: 400;
              line-height: 20px;
              color: #333333;
              opacity: 1;
            }
          }

          .comment-content-reply {
            display: flex;
            justify-content: space-between;

            .comment-time {
              font-size: 12px;
              font-family: Noto Sans SC;
              font-weight: 400;
              line-height: 17px;
              color: #999999;
              opacity: 1;
            }

            .comment-reply {
              cursor: pointer;
              margin: 0;
              font-size: 12px;
              font-family: Noto Sans SC;
              /*font-weight: bold;*/
              line-height: 17px;
              color: #666666;
              opacity: 1;
            }
          }
        }
      }
    }

    //回复
    .storm-comment-reply {
      border-bottom: 1px solid #ececec;
      padding: 0 0 20px 0;

      .storm-user-comment-message-reply {
        display: flex;
        align-items: center;
        margin-left: 50px;

        .storm-user-header {
          width: 42px;
          height: 80px;
          margin-bottom: 12px;
          cursor: pointer;

          .storm-user-img {
            border: 1px solid #ffffff;
            width: 42px;
            height: 42px;
            border-radius: 50%;

            img {
              width: 42px;
              height: 42px;
              border-radius: 50%;
              margin-top: 12px;
            }
          }

          .storm-user-img-ismember {
            border: 1px solid #ffffff;
            border-radius: 50%;
            background-image: url(../../../static/img/userData/user_message_vip_bg.png);
            background-size: 100%;
            width: 42px;
            height: 52px;

            img {
              width: 42px;
              height: 42px;
              border-radius: 50%;
              margin-top: 12px;
            }
          }
        }

        .storm-comment-content {
          // min-width: 270px;
          width: 100%;
          margin-left: 10px;

          .comment-content-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .comment-user-name {
              font-size: 12px;
              font-family: Noto Sans SC;
              font-weight: inherit;
              line-height: 17px;
              color: #333333;
              opacity: 1;
              max-height: 20px;

              span {
                overflow: hidden;
                text-overflow: ellipsis;
                width: 60px;
              }

              .comment-author-logo {
                margin-left: 26px;
                padding: 3px 6px;
                border-radius: 2px;
                background: #ec705a;
                color: #ffffff;
              }
            }

            .comment-cotnent-detials {
              .chips-details-single {
                float: left;
                margin-left: 20px;
                display: flex;
                align-items: center;
                cursor: pointer;
              }
            }
          }

          .comment-content-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 6px;

            .comment-content-text {
              width: 266px;
              font-size: 14px;
              font-family: Noto Sans SC;
              font-weight: 400;
              line-height: 20px;
              color: #333333;
              opacity: 1;
            }
          }

          .comment-content-reply {
            display: flex;
            justify-content: space-between;

            .comment-time {
              font-size: 12px;
              font-family: Noto Sans SC;
              font-weight: 400;
              line-height: 17px;
              color: #999999;
              opacity: 1;
            }

            .comment-reply {
              cursor: pointer;
              margin: 0;
              font-size: 12px;
              font-family: Noto Sans SC;
              /*font-weight: bold;*/
              line-height: 17px;
              color: #666666;
              opacity: 1;
            }
          }
        }

        //回复
        // .comment-reply-from{
        //   width: 100%;
        //   height: 50px;
        //   border: 1px solid red;
        //   margin: 0 13px;
        // }
      }

      .comment-reply-more {
        display: flex;
        margin-left: 50px;
        font-size: 12px;
        font-family: Noto Sans SC;
        font-weight: 400;
        line-height: 17px;
        color: #666666;
        opacity: 1;
        cursor: pointer;
        align-items: center;

        .more-comment-logo {
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 6px solid #666666;
          border-radius: 3px;
          margin-left: 6px;
        }
      }
    }
  }
  
</style>
